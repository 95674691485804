/** @format */

import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {
  AbstractControl,
  AsyncValidatorFn,
  FormGroup,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';
import {MatOption} from '@angular/material/core';
import {MatDialog} from '@angular/material/dialog';
import {ModalController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {get, includes, intersection, filter as lfilter, map as lmap, merge, orderBy, toLower, values} from 'lodash-es';
import {Observable, filter, lastValueFrom, of} from 'rxjs';
import {debounceTime, map, startWith, switchMap, tap} from 'rxjs/operators';
import {
  Equipment,
  EquipmentKindFireSafetyCategory,
  Family,
  Inspection,
  InspectionService,
  InspectionStateOptions,
  InspectionStatus,
  InspectionStatusColor,
  Intervention,
  InterventionStatusColor,
  InterventionStatusTooltip,
  Quotation,
  RealEstateStructure,
  RealEstateStructureKind,
  RealEstateStructureKindReferencePrefix,
  fadeOut,
} from 'sesio-lib';
import {SubSink} from 'subsink';
import {v4} from 'uuid';
import {environment} from '../../../../environments/environment';
import {InterventionService} from '../../../_services/intervention.service';
import {QuotationService} from '../../../_services/quotation.service';
import {RealEstateStructureService} from '../../../_services/real-estate-structure.service';
import {ConfirmDialogComponent} from '../../confirm-dialog/confirm-dialog.component';
import {DateFilterComponent} from '../../date-filter/date-filter.component';
import {Location} from '../../map/_classes/location';
import {LocationService} from '../../map/location.service';
import {MapComponent} from '../../map/map.component/map.component';

interface IEquipment extends Equipment {
  interventions?: Promise<Intervention[]>;
}

@Component({
  selector: 'app-real-estate-structure-info',
  templateUrl: './real-estate-structure-info.component.html',
  styleUrls: ['./real-estate-structure-info.component.scss'],
  animations: [fadeOut],
})
export class RealEstateStructureInfoComponent implements OnInit, OnDestroy {
  repo = environment.repoUrl;
  RealEstateStructureKind = RealEstateStructureKind;
  InspectionStateOptions = InspectionStateOptions;
  InspectionStatusColor = InspectionStatusColor;
  InterventionStatusColor = InterventionStatusColor;
  InterventionStatusTooltip = InterventionStatusTooltip;
  RealEstateStructureKindReferencePrefix = RealEstateStructureKindReferencePrefix;

  equipmentKindFireSafetyCategories = orderBy(
    lmap(values(EquipmentKindFireSafetyCategory), value => ({
      name: this.translate.instant(value),
      value,
    })),
    ['name']
  );

  inspectionStatuses = orderBy(
    lmap(values(InspectionStatus), value => ({
      name: this.translate.instant(value),
      value,
      color: InspectionStatusColor[value],
    })),
    ['name']
  );

  DocumentColumn = ['date', 'kind', 'filename', 'contractor', 'depositary', 'title', 'vrs', 'description'];

  @Input()
  id: string;

  @Input()
  disabled: boolean = true;

  @ViewChild('searchLocation', {read: MatAutocompleteTrigger})
  public searchLocationRef: MatAutocompleteTrigger;
  public map: MapComponent;

  esi: RealEstateStructure;
  parent: RealEstateStructure;

  equipments: IEquipment[];
  interventions: Intervention[];
  quotations: Quotation[];
  formGroup: FormGroup;
  addressControl = new UntypedFormControl();
  additionalControl = new UntypedFormControl();
  searching = false;
  public options: Observable<Location[]>;
  loading = true;

  private subsink = new SubSink();

  constructor(
    private modalController: ModalController,
    private formBuilder: UntypedFormBuilder,
    private realEstateStructureService: RealEstateStructureService,
    private inspectionService: InspectionService,
    private interventionService: InterventionService,
    private quotationService: QuotationService,
    private translate: TranslateService,
    private locationService: LocationService,
    private changeDetectorRef: ChangeDetectorRef,
    private dialog: MatDialog
  ) {}

  async ngOnInit() {
    if (this.id) this.esi = await lastValueFrom(this.realEstateStructureService.get(this.id));
    if (this.esi?.parent) this.parent = await lastValueFrom(this.realEstateStructureService.get(this.esi.parent));
    this.buildFormGroup();
    this.loading = false;
    this.changeDetectorRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  async dismiss(relaod = false): Promise<void> {
    await this.modalController.dismiss(relaod);
  }

  showMarker(mapComponentRef: MapComponent) {
    if (mapComponentRef) {
      this.map = mapComponentRef;
      mapComponentRef.removeMarkers();
      if (this.esi?.location?.coordinates?.length === 2) {
        mapComponentRef.addMarker({
          type: 'default',
          color: 'var(--ion-color-primary)',
          position: this.esi?.location.coordinates,
        });
        mapComponentRef.flyTo(this.esi?.location.coordinates);
      }
    }
  }

  async remove() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'Suppression de structure immobilière',
        message: `Êtes-vous sûr de vouloir supprimer la structure immobilière ${this.esi.reference}`,
      },
    });
    const confirmed = await lastValueFrom(dialogRef.afterClosed());
    if (!confirmed) return;
    await lastValueFrom(this.realEstateStructureService.delete(this.esi?.id));
    this.dismiss(true);
  }

  async save() {
    const value = this.formGroup.getRawValue();
    merge(
      value,
      {
        reference: this.esi
          ? this.esi.reference
          : value.organizationalUnit.code + RealEstateStructureKindReferencePrefix[value.kind] + value.reference,
      },
      {parent: this.formGroup.value.parent?.id},
      {location: this.addressControl.value},
      {location: {additional: this.additionalControl.value}}
    );
    if (!!this.esi) {
      await lastValueFrom(this.realEstateStructureService.update(this.esi.id, value));
    } else {
      await lastValueFrom(this.realEstateStructureService.create(value));
    }
    this.dismiss(true);
  }

  private async buildFormGroup() {
    this.formGroup = this.formBuilder.group({
      s3Path: [this.esi?.s3Path || v4()],
      organizationalUnit: [
        {value: this.esi?.organizationalUnit, disabled: this.esi || this.disabled},
        Validators.required,
      ],
      parent: [{value: this.parent, disabled: this.disabled}],
      kind: [{value: this.esi?.kind, disabled: this.esi || this.disabled}, Validators.required],
      reference: [
        {value: this.esi?.reference, disabled: this.esi || this.disabled},
        {
          validators: [Validators.required],
          asyncValidators: [this.uniqueReferenceValidator()],
          updateOn: 'blur',
        },
      ],
      name: [{value: this.esi?.name, disabled: this.disabled}, Validators.required],
      description: [{value: this.esi?.description, disabled: this.disabled}],
    });

    this.subsink.add(
      this.formGroup.valueChanges
        .pipe(
          tap(value => {
            if (!this.esi && !this.disabled && !!value.organizationalUnit && !!value.kind) {
              if (this.formGroup.get('reference').disabled) this.formGroup.get('reference').enable();
            } else if (this.formGroup.get('reference').enabled) this.formGroup.get('reference').disable();
          })
        )
        .subscribe()
    );

    this.options = this.addressControl.valueChanges.pipe(
      filter(value => typeof value === 'string' && value.length > 0),
      debounceTime(500),
      tap(() => (this.searching = true)),
      switchMap(query => this.locationService.search({query, countries: ['FR']})),
      tap(() => (this.searching = false)),
      filter(locations => this.searchLocationRef && !!locations.length),
      tap(() => this.searchLocationRef.openPanel())
    );
    const location = get(this.esi, 'location', {additional: null});
    this.addressControl.setValue(location, {emitEvent: false});
    this.additionalControl.setValue(location.additional);
  }

  public displayOption(option: Location): string {
    return option && option.address ? option.address.formatted : '';
  }

  public selected(option: MatOption): void {
    this.setLocation(option.value);
  }

  private setLocation(location: Location): void {
    this.addressControl.setValue(location, {emitEvent: false});
    this.map?.removeMarkers();
    this.map?.addMarker({type: 'default', color: 'var(--ion-color-primary)', position: location.coordinates});
    this.map?.flyTo(location.coordinates);
  }

  private loadingEquipments = false;
  async loadEquipments(visible: boolean) {
    if (this.equipments || !visible || this.loadingEquipments) return;
    this.loadingEquipments = true;
    this.equipments = orderBy(
      await this.realEstateStructureService.getEquipments(this.esi.id, {families: [Family.FIRE_SAFETY]}).toPromise(),
      ['reference'],
      ['desc']
    );
  }

  inspections: Inspection[];
  inspectionFormGroup: UntypedFormGroup;
  filteredInspections: Observable<Inspection[]>;
  private inspectionsLoaded = false;
  async loadInspections(visible: boolean) {
    if (this.inspections || !visible || this.inspectionsLoaded) return;
    this.inspectionsLoaded = true;
    this.inspectionFormGroup = this.formBuilder.group({
      states: [[]],
      reference: [''],
      kinds: [[]],
      statuses: [[]],
      plannedDate: [],
      startDate: [],
      endDate: [],
      deadline: [],
    });
    this.inspections = orderBy(
      await this.inspectionService.list({realEstateStructureIds: [this.esi.id]}).toPromise(),
      [
        inspection =>
          get(inspection, 'info.startDate', get(inspection, 'info.endDate', get(inspection, 'config.deadline'))),
      ],
      ['desc']
    );
    this.filteredInspections = this.inspectionFormGroup.valueChanges.pipe(
      startWith({}),
      map(value =>
        lfilter(this.inspections, inspection => {
          return (
            (!value.states?.length || includes(value.states, inspection.info?.state)) &&
            (!value.reference?.length || includes(toLower(inspection.reference), toLower(value.reference))) &&
            (!value.kinds?.length || !!intersection(value.kinds, inspection.equipmentCategories).length) &&
            (!value.statuses?.length || includes(value.statuses, inspection.status)) &&
            DateFilterComponent.filter(inspection.info.plannedDate, value.plannedDate) &&
            DateFilterComponent.filter(inspection.info.startDate, value.startDate) &&
            DateFilterComponent.filter(inspection.info.endDate, value.endDate) &&
            DateFilterComponent.filter(inspection.config.deadline, value.deadline)
          );
        })
      )
    );
  }

  private loadingInterventions = false;
  async loadInterventions(visible: boolean) {
    if (this.interventions || !visible || this.loadingInterventions) return;
    this.loadingInterventions = true;
    this.interventions = orderBy(
      await this.interventionService.list({realEstateStructureIds: [this.esi.id]}).toPromise(),
      ['createdAt'],
      ['desc']
    );
  }

  private loadingQuotations = false;
  async loadQuotations(visible: boolean) {
    if (this.quotations || !visible || this.loadingQuotations) return;
    this.loadingQuotations = true;
    this.quotations = orderBy(
      await this.quotationService.list({realEstateStructureIds: [this.esi.id]}).toPromise(),
      ['createdAt'],
      ['desc']
    );
  }

  async loadEquipmentInterventions(equipment: IEquipment) {
    if (equipment.interventions) return;
    equipment.interventions = this.interventionService.list({equipmentIds: [equipment.id]}).toPromise();
  }

  private uniqueReferenceValidator(): AsyncValidatorFn {
    return (control: AbstractControl): Observable<{[key: string]: any} | null> => {
      if (this.esi || control.value === null || control.value === '') {
        return of(null);
      }
      const reference =
        this.formGroup.get('organizationalUnit').value.code +
        RealEstateStructureKindReferencePrefix[this.formGroup.get('kind').value] +
        control.value;
      return this.realEstateStructureService
        .referenceExists(reference)
        .pipe(map(res => (!!res ? {unique: reference} : null)));
    };
  }
}
