/** @format */

import {animate, state, style, transition, trigger} from '@angular/animations';
import {ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {AnimationController, ModalController, Platform, ToastController} from '@ionic/angular';
import {TranslateService} from '@ngx-translate/core';
import {each, find, includes, keys, startsWith} from 'lodash-es';
import moment from 'moment';
import {filter} from 'rxjs/operators';
import {SubSink} from 'subsink';
import {environment} from '../environments/environment';
import {User} from './_classes/user';
import {TutorialService} from './_components/help/tutorial.service';
import {StatisticsModal} from './_components/statistics/statistics.modal';
import {AuthService} from './_services/auth.service';
import {CustomIconService} from './_services/custom-icon.service';
import {SessionService} from './_services/session.service';
import {UserService} from './_services/user.service';

interface IAction {
  name: string;
  color: string;
  icon: string;
  click: () => any;
  visible: boolean;
}
interface IApp {
  name: string;
  color: string;
  link: string;
  icon: string;
  src?: boolean;
  material?: boolean;
  access?: 'coming-soon' | 'no-access-rights';
  separator?: boolean;
  hidden?: boolean;
  isHidden?: (user: User) => boolean;
}

@Component({
  selector: 'app-root',
  animations: [
    trigger('openCloseMenu', [
      state('open', style({width: '250px'})),
      state('closed', style({width: '72px'})),
      transition('* => *', [animate('0.3s')]),
    ]),
    trigger('openCloseMenuItem', [
      state('open', style({width: 'inherit', padding: '*'})),
      state('closed', style({width: '0', padding: '0'})),
      transition('* => *', [animate('0.3s')]),
    ]),
    trigger('openCloseMenuContent', [
      state('open', style({left: '250px'})),
      state('closed', style({left: '72px'})),
      transition('closed => open', [animate('0.3s')]),
      transition('open => closed', [animate('0.3s')]),
    ]),
  ],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public appName: string;
  public fullscreen = true;
  public isMenuOpen = false;
  public user: User;

  actions: IAction[] = [
    {name: 'statistics', color: 'primary', icon: 'insights', click: this.presentStatistics.bind(this), visible: false},
    {
      name: 'report',
      color: 'primary',
      icon: 'edit_document',
      click: () => this.router.navigateByUrl('/report'),
      visible: false,
    },
    {name: 'notifications', color: 'primary', icon: 'notifications', click: () => {}, visible: false},
  ];
  routeActions = {
    '/dashboard': ['report'],
  };

  public apps: IApp[] = [
    {
      name: 'equipment-space',
      color: 'my-perimeter',
      link: '/dashboard',
      icon: 'apartment',
      material: true,
    },
    {
      name: 'elevator-space',
      color: 'primary',
      link: '/elevator-space',
      icon: 'assets/icon/elevator.svg',
      src: true,
      access: null,
    },
    {
      name: 'parking-door-space',
      color: 'parking-door',
      link: '/parking-door-space',
      icon: 'assets/icon/parking-door.svg',
      src: true,
    },
    {
      name: 'optical-fiber-space',
      color: 'optical-fiber',
      link: '/optical-fiber-space',
      icon: 'assets/icon/optical-fiber.svg',
      src: true,
    },
    {
      name: 'relay-antenna-space',
      color: 'relay-antenna',
      link: '/relay-antenna-space',
      icon: 'assets/icon/relay-antenna.svg',
      src: true,
    },
    {
      name: 'photovoltaic-panel-space',
      color: 'photovoltaic-panel',
      link: '/photovoltaic-panel-space',
      icon: 'assets/icon/photovoltaic-panel.svg',
      src: true,
      access: 'coming-soon',
    },
    {
      name: 'access-control-space',
      color: 'access-control',
      link: '/access-control-space',
      icon: 'assets/icon/access-control.svg',
      src: true,
    },
    {
      name: 'energy-fluid-space',
      color: 'energy-fluid',
      link: '/energy-fluid-space',
      icon: 'assets/icon/energy-fluid.svg',
      src: true,
      separator: true,
    },
    {
      name: 'fire-safety-space',
      color: 'fire-safety',
      link: '/fire-safety-space',
      icon: 'assets/icon/fire-safety.svg',
      src: true,
      separator: true,
    },
    {
      name: 'isidesk-space',
      color: 'medium',
      link: 'isidesk-space',
      icon: 'assets/icon/isidesk.svg',
      src: true,
      separator: true,
    },
    {
      name: 'administration-space',
      color: 'dark',
      link: '/administration',
      icon: 'settings-sharp',
      access: null,
    },
  ];
  public active: IApp;

  private toast: HTMLIonToastElement;
  private statisticModal: HTMLIonModalElement;

  private subsink = new SubSink();

  constructor(
    private platform: Platform,
    private titleService: Title,
    private router: Router,
    private translate: TranslateService,
    private toastController: ToastController,
    private authService: AuthService,
    private userService: UserService,
    private customIconService: CustomIconService,
    private sessionService: SessionService,
    private animationCtrl: AnimationController,
    private modalController: ModalController,
    private tutorialService: TutorialService,
    private changeDetectorRef: ChangeDetectorRef
  ) {
    this.init();
  }

  ngOnInit(): void {
    this.titleService.setTitle(environment.title);
    this.appName = environment.appName;
    this.setUser(this.userService.$user.value);
    this.sessionService.getMenuState().then(menuState => (this.isMenuOpen = menuState));
    this.subsink.add(this.userService.$user.subscribe(user => this.setUser(user)));
    this.subsink.add(
      this.router.events.pipe(filter(event => event instanceof NavigationStart)).subscribe((event: NavigationStart) => {
        this.fullscreen = event.url.startsWith('/access-control/') || event.url.startsWith('/login');
        this.loadUser();
      }),
      this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
        this.active = this.apps.find(app => event.url.startsWith(app.link));
        const actions: string[] | undefined =
          this.routeActions[find(keys(this.routeActions), key => startsWith(event.url, key))];
        each(this.actions, action => {
          action.visible = !actions || includes(actions, action.name);
        });
        this.changeDetectorRef.detectChanges();
      })
    );
  }

  ngOnDestroy(): void {
    this.subsink.unsubscribe();
  }

  public toggleMenuOpen(): void {
    this.isMenuOpen = !this.isMenuOpen;
    this.sessionService.setMenuState(this.isMenuOpen);
  }

  public async info(app: IApp): Promise<any> {
    if (this.toast) {
      this.toast.dismiss();
    }
    let message;
    switch (app.access) {
      case 'coming-soon':
        message = 'coming-soon';
        break;
      case 'no-access-rights':
        message = 'no-access-rights';
        break;
      default:
        return null;
    }
    return this.showToast(message);
  }

  public async showToast(message: string): Promise<void> {
    this.toast = await this.toastController.create({
      message: await this.translate.get(message).toPromise(),
      cssClass: 'toast-text-centered toast-text-title',
      duration: 2000,
      position: 'middle',
    });
    this.toast.present();
  }

  public logout(): void {
    this.authService.logout();
    this.router.navigate(['/login']);
  }

  public tutorial(): void {
    this.tutorialService.$replay.next(true);
  }

  async presentStatistics(): Promise<void> {
    if (this.statisticModal) return;
    this.statisticModal = await this.modalController.create({
      component: StatisticsModal,
      cssClass: 'statistics-modal no-transform',
    });
    this.statisticModal.onWillDismiss().then(() => (this.statisticModal = null));
    await this.statisticModal.present();
  }

  private async init(): Promise<void> {
    this.customIconService.init();
    this.translate.setDefaultLang('fr');
    this.translate.use('fr');
    moment.locale('fr');
    await this.platform.ready();
    this.loadUser();
  }

  private async setUser(user: User): Promise<void> {
    this.user = user;
    this.apps.forEach(app => {
      app.hidden =
        !this.user ||
        (app.isHidden
          ? app.isHidden(user)
          : !this.user.administrator && !this.user.spaces.includes(app.name.replace(/-space$/, '')));
    });
  }

  private async loadUser(): Promise<void> {
    if (await this.authService.isAuthenticated()) {
      await this.userService.loadUser();
    }
  }
}
