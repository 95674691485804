/** @format */

import {map, values} from 'lodash-es';
import {propertyMap} from 'model-mapper';
import {EmbededContractor} from './embeded-contractor';
import {BoilerUsageKind, EnergyKind, IMetricsSetpoint, Metric, Metrics} from './metrics';
import {RealEstateStructure} from './real-estate-structure';
import {RealEstateStructureAlertKind} from './real-estate-structure-alert';

export enum BoilerRoomState {
  OK = 'ok',
  OUT_OF_ORDER = 'out-of-order',
  ANOMALY = 'anomaly',
  STOPPED = 'stopped',
}

export const BoilerRoomStateName = {
  [BoilerRoomState.OK]: 'En fonctionnement',
  [BoilerRoomState.OUT_OF_ORDER]: 'En panne',
  [BoilerRoomState.ANOMALY]: 'Anomalie',
  [BoilerRoomState.STOPPED]: "À l'arrêt",
};

export const BoilerRoomStateColor = {
  [BoilerRoomState.OK]: 'var(--ion-color-success)',
  [BoilerRoomState.OUT_OF_ORDER]: 'var(--ion-color-danger)',
  [BoilerRoomState.ANOMALY]: 'var(--ion-color-warning)',
  [BoilerRoomState.STOPPED]: 'var(--ion-color-black)',
};

export const BoilerRoomStateOptions: {value: string; name: string; color: string}[] = map(
  values(BoilerRoomState),
  value => ({value, name: BoilerRoomStateName[value], color: BoilerRoomStateColor[value]})
);

export enum BoilerRoomMode {
  HEATING_WATER_USAGE = 'heating-water-usage', // Chauffage et Eau Chaude Sanitaire => ECS uniquement quand période de chauffe = Non
  HEATING_USAGE = 'heating-usage', // Chauffage uniquement => A l'arrêt l'été
  WATER_USAGE = 'water-usage', // Eau Chaude Sanitaire uniquement
  STOPPED = 'stopped', // À l'arrêt
  MAINTENANCE = 'maintenance', // En maintenance
  WORK_PENDING = 'work-pending', // En travaux
}

export const BoilerRoomModeName = {
  [BoilerRoomMode.HEATING_WATER_USAGE]: 'Chauffage et Eau Chaude Sanitaire',
  [BoilerRoomMode.HEATING_USAGE]: 'Chauffage uniquement',
  [BoilerRoomMode.WATER_USAGE]: 'Eau Chaude Sanitaire uniquement',
  [BoilerRoomMode.STOPPED]: "À l'arrêt",
  [BoilerRoomMode.MAINTENANCE]: 'En maintenance',
  [BoilerRoomMode.WORK_PENDING]: 'En travaux',
};

export const BoilerRoomModeColor = {
  [BoilerRoomMode.HEATING_WATER_USAGE]: '#7028d2',
  [BoilerRoomMode.HEATING_USAGE]: '#aa7de5',
  [BoilerRoomMode.WATER_USAGE]: 'var(--ion-color-primary)',
  [BoilerRoomMode.STOPPED]: 'var(--ion-color-black)',
  [BoilerRoomMode.MAINTENANCE]: 'var(--ion-color-maintenance)',
  [BoilerRoomMode.WORK_PENDING]: 'var(--ion-color-warning)',
};

export class BoilerRoomMetrics {
  @propertyMap()
  [Metric.TEMPERATURE_INDOOR]: number;

  @propertyMap()
  [Metric.TEMPERATURE_OUTDOOR]: number;

  @propertyMap()
  [Metric.PRIMARY]: number;

  @propertyMap()
  [Metric.PRIMARY_ARRIVAL]: number;

  @propertyMap()
  [Metric.TEMPERATURE_WATER_DEPARTURE]: number;

  @propertyMap()
  [Metric.TEMPERATURE_WATER_ARRIVAL]: number;

  @propertyMap()
  [Metric.TEMPERATURE_HEATING_DEPARTURE]: number;

  @propertyMap()
  [Metric.TEMPERATURE_HEATING_ARRIVAL]: number;

  @propertyMap()
  [Metric.HUMIDITY_INDOOR]: number;

  @propertyMap()
  [Metric.HUMIDITY_OUTDOOR]: number;

  @propertyMap()
  [Metric.AIR_QUALITY_INDOOR]: number;

  @propertyMap()
  [Metric.AIR_QUALITY_OUTDOOR]: number;

  @propertyMap()
  [Metric.GREEN_ENERGY]: number;

  @propertyMap()
  [Metric.TAG_ENERGY]: number;

  @propertyMap()
  [Metric.TAG_CLIMAT]: number;

  @propertyMap()
  [Metric.POWER]: number;

  @propertyMap()
  [Metric.SOLAR_POWER]: number;

  @propertyMap()
  [Metric.BOILER_USAGE_KIND]: BoilerUsageKind;

  @propertyMap()
  [Metric.ENERGY_KIND]: EnergyKind;

  @propertyMap()
  [Metric.LOWER_CALORIFIC_VALUE]: number;

  @propertyMap()
  [Metric.HIGHER_CALORIFIC_VALUE]: number;

  @propertyMap()
  [Metric.PRESSURE]: number;

  @propertyMap()
  [Metric.SETPOINT_TEMPERATURE_HEATING]: number;

  @propertyMap()
  [Metric.SETPOINT_TEMPERATURE_WATER]: number;

  @propertyMap({default: 2})
  [Metric.SETPOINT_PRESSURE]: number;

  @propertyMap()
  [Metric.SETPOINT]: IMetricsSetpoint[];
}

export class BoilerRoomSubstation {
  @propertyMap()
  public reference: string;

  @propertyMap()
  public groupId?: string;

  @propertyMap()
  public description?: string;

  @propertyMap()
  public location?: string;
}

export class BoilerRoomLinkedGroup {
  @propertyMap({source: '_id'})
  public id: string;

  @propertyMap()
  public path: string;

  @propertyMap()
  public code: string;

  @propertyMap()
  public name: string;
}

export class AnnualConsumption {
  @propertyMap()
  public name: string;

  @propertyMap()
  public co2: number;

  @propertyMap()
  public january?: number;

  @propertyMap()
  public february?: number;

  @propertyMap()
  public march?: number;

  @propertyMap()
  public april?: number;

  @propertyMap()
  public may?: number;

  @propertyMap()
  public june?: number;

  @propertyMap()
  public july?: number;

  @propertyMap()
  public august?: number;

  @propertyMap()
  public september?: number;

  @propertyMap()
  public october?: number;

  @propertyMap()
  public november?: number;

  @propertyMap()
  public december?: number;
}

export class AnnualProduction {
  @propertyMap()
  public name: string;
  
  @propertyMap()
  public co2: number;

  @propertyMap()
  public january?: number;

  @propertyMap()
  public february?: number;

  @propertyMap()
  public march?: number;

  @propertyMap()
  public april?: number;

  @propertyMap()
  public may?: number;

  @propertyMap()
  public june?: number;

  @propertyMap()
  public july?: number;

  @propertyMap()
  public august?: number;

  @propertyMap()
  public september?: number;

  @propertyMap()
  public october?: number;

  @propertyMap()
  public november?: number;

  @propertyMap()
  public december?: number;
}

export class AnnualData {
  @propertyMap()
  public year: number;

  @propertyMap({type: [AnnualConsumption]})
  public consumptions: AnnualConsumption[];

  @propertyMap({type: [AnnualProduction]})
  public productions: AnnualProduction[];

  @propertyMap()
  public energyTag?: number;

  @propertyMap()
  public climatTag?: number;
}

export class BoilerRoomMetadata {
  @propertyMap({type: EmbededContractor})
  public contractor: EmbededContractor;

  @propertyMap()
  public linked: boolean;

  @propertyMap()
  public state: BoilerRoomState;

  @propertyMap()
  public mode: BoilerRoomMode;

  @propertyMap()
  public groupSurface?: number;

  @propertyMap({type: [BoilerRoomLinkedGroup]})
  public linkedGroups: BoilerRoomLinkedGroup[];

  @propertyMap({type: [BoilerRoomSubstation]})
  public substations: BoilerRoomSubstation[];

  @propertyMap({type: [AnnualData]})
  annualData: [AnnualData];
}

export class BoilerRoom extends RealEstateStructure {
  @propertyMap({type: BoilerRoomMetrics})
  public metrics: BoilerRoomMetrics;

  @propertyMap({type: BoilerRoomMetadata})
  public metadata: BoilerRoomMetadata;
}

export const BoilerRoomMetricCodes = [
  Metric.TEMPERATURE_INDOOR,
  Metric.TEMPERATURE_OUTDOOR,
  Metric.PRIMARY,
  Metric.PRIMARY_ARRIVAL,
  Metric.TEMPERATURE_WATER_DEPARTURE,
  Metric.TEMPERATURE_WATER_ARRIVAL,
  Metric.TEMPERATURE_HEATING_DEPARTURE,
  Metric.TEMPERATURE_HEATING_ARRIVAL,
  Metric.HUMIDITY_INDOOR,
  Metric.HUMIDITY_OUTDOOR,
  Metric.AIR_QUALITY_INDOOR,
  Metric.AIR_QUALITY_OUTDOOR,
  Metric.GREEN_ENERGY,
  Metric.TAG_ENERGY,
  Metric.TAG_CLIMAT,
  Metric.POWER,
  Metric.SOLAR_POWER,
  Metric.BOILER_USAGE_KIND,
  Metric.ENERGY_KIND,
  Metric.LOWER_CALORIFIC_VALUE,
  Metric.HIGHER_CALORIFIC_VALUE,
  Metric.PRESSURE,

  Metric.SETPOINT_TEMPERATURE_HEATING,
  Metric.SETPOINT_TEMPERATURE_WATER,
  Metric.SETPOINT_PRESSURE,
  Metric.SETPOINT,
];

export const BoilerRoomMetricSetpointCodes = [
  Metric.SETPOINT_TEMPERATURE_HEATING,
  Metric.SETPOINT_TEMPERATURE_WATER,
  Metric.SETPOINT_PRESSURE,
  Metric.SETPOINT,
];

export const BoilerRoomMetricList = Metrics.filter(m => {
  return BoilerRoomMetricCodes.includes(m.code);
});

export const BoilerRoomAlerts = [
  RealEstateStructureAlertKind.PRIMARY_TOO_LOW,
  RealEstateStructureAlertKind.PRIMARY_TOO_HIGH,
  RealEstateStructureAlertKind.TEMPERATURE_WATER_DEPARTURE_TOO_LOW,
  RealEstateStructureAlertKind.TEMPERATURE_WATER_DEPARTURE_TOO_HIGH,
  RealEstateStructureAlertKind.TEMPERATURE_WATER_ARRIVAL_TOO_LOW,
  RealEstateStructureAlertKind.TEMPERATURE_HEATING_DEPARTURE_TOO_LOW,
  RealEstateStructureAlertKind.TEMPERATURE_HEATING_DEPARTURE_TOO_HIGH,
  RealEstateStructureAlertKind.PRESSURE_TOO_HIGH,
];
