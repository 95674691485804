/** @format */

import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {TranslateService} from '@ngx-translate/core';
import * as Highcharts from 'highcharts/highstock';
import {each, get, isEqual, map} from 'lodash-es';
import moment, {Moment} from 'moment';
import {Subscription, lastValueFrom} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {
  AggregationValue,
  ChartComponent,
  Metric,
  Sensor,
  SensorDataService,
  fadeOut,
  highchartsOptions,
  palette,
} from 'sesio-lib';
import {SensorService} from '../../../../_services/sensor.service';

interface IRange {
  start: Moment;
  end: Moment;
}

interface IAverageChartData {
  name: string;
  averages: [number, number][];
  ranges: [number, number, number][];
}

@Component({
  selector: 'app-heating-chart',
  templateUrl: './heating-chart.component.html',
  styleUrls: ['./heating-chart.component.scss'],
  animations: [fadeOut],
})
export class HeatingChartComponent implements OnInit, OnDestroy {
  kinds: Metric[] = [
    Metric.TEMPERATURE_HEATING_DEPARTURE,
    Metric.TEMPERATURE_HEATING_ARRIVAL,
    Metric.TEMPERATURE_OUTDOOR,
  ];

  @Input()
  public realEstateStructureId: string;

  @Input('range')
  public set setRange(range: UntypedFormGroup) {
    if (this.rangeSub) {
      this.rangeSub.unsubscribe();
      this.rangeSub = null;
    }
    this.range = range;
    if (this.range) {
      this.rangeValue = this.range.value;
      this.rangeSub = this.range.valueChanges.pipe(debounceTime(100)).subscribe({
        next: value => {
          if (value && value.start && value.end && !isEqual(this.rangeValue, value) && this.chart) {
            this.rangeValue = value;
            this.loadData();
          }
        },
      });
    } else {
      this.rangeValue = null;
    }
  }

  options = {
    chart: {
      zoomType: 'x',
    },
    title: '',
    exporting: {
      buttons: {
        contextButton: {
          menuItems: [
            'viewFullscreen',
            'printChart',
            'separator',
            'downloadPNG',
            'downloadJPEG',
            'downloadPDF',
            'downloadSVG',
            'separator',
            'downloadCSV',
            'downloadXLS',
            'openInCloud',
          ],
        },
      },
    },
    credits: {enabled: false},
    boost: {
      useGPUTranslations: true,
    },
    data: {dateFormat: 'dd/mm/YYYY'},
    rangeSelector: {enabled: false, inputEnabled: false},
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 500,
          },
          chartOptions: {
            legend: {
              itemWidth: 200,
            },
          },
        },
      ],
    },
    legend: {
      enabled: true,
      verticalAlign: 'top',
    },
    tooltip: {
      shared: true,
      split: false,
      useHTML: true,
      headerFormat: '<small>{point.key}</small><br/>',
    },
    xAxis: {
      type: 'datetime',
      labels: {
        align: 'left',
        x: 5,
        y: 14,
        format: '{value:%d %b}', // long month
      },
      tickmarkPlacement: 'on',
      startOnTick: true,
      endOnTick: true,
      events: {
        afterSetExtremes: event => {
          if (event.type !== 'setExtremes') return;
          if (event.min !== this.rangeValue?.start.valueOf() || event.max !== this.rangeValue?.end.valueOf()) {
            this.rangeValue = {start: moment(event.min), end: moment(event.max)};
            this.range.setValue(this.rangeValue);
            this.loadData();
          }
        },
      },
    },
    yAxis: {
      opposite: false,
      allowDecimals: false,
      title: {
        text: '°C',
        offset: 0,
        align: 'high',
        rotation: 0,
        style: {
          fontSize: '10px',
          color: '#FF3333',
        },
        textAlign: 'left',
        x: -18,
      },
      labels: {
        format: '{value:.0f}',
        style: {
          fontSize: '8px',
          color: '#FF3333',
        },
        x: -3,
      },
      plotLines: [
        {
          value: 0,
          color: '#BBBBBB',
          width: 1,
          zIndex: 2,
        },
      ],
      maxPadding: 0.3,
      minRange: 8,
      tickInterval: 1,
      gridLineColor: 'rgba(128, 128, 128, 0.1)',
      lineWidth: 1,
    },
    series: [],
  };

  private range: UntypedFormGroup;
  private rangeValue: IRange;
  private rangeSub: Subscription;
  private sensors: Sensor[];

  constructor(
    private translate: TranslateService,
    private sensorService: SensorService,
    private sensorDataService: SensorDataService
  ) {}

  ngOnInit(): void {
    Highcharts.setOptions(highchartsOptions[this.translate.getDefaultLang()]);
  }

  ngOnDestroy(): void {
    if (this.rangeSub) this.rangeSub.unsubscribe();
  }

  private chart: ChartComponent;
  public async load(chart: ChartComponent) {
    if (chart) this.chart = chart;
    else if (!this.chart) return;
    this.sensors = await this.sensorService.list({
      realEstateStructureId: this.realEstateStructureId,
      kinds: this.kinds,
    });
    const series: Highcharts.SeriesOptionsType[] = map(this.sensors, (sensor, index) => ({
      type: sensor.kind === Metric.TEMPERATURE_OUTDOOR ? 'area' : 'line',
      name: sensor.name,
      zIndex: 1,
      color: sensor.color,
      marker: {fillColor: 'white', lineWidth: 2, lineColor: palette[index]},
      tooltip: {
        pointFormat: '<span style="color:{point.color}">\u25CF</span> ' + '{series.name}: <b>{point.y:.1f}°C</b><br/>',
      },
    }));
    this.chart.setSeries(series);
    await this.loadData();
  }

  private async loadData(): Promise<void> {
    this.chart.setLoading(true);
    const unitGrouping =
      this.rangeValue.end?.diff(this.rangeValue.start, 'months', true) > 0.99
        ? 'day'
        : this.rangeValue.end?.diff(this.rangeValue.start, 'days', true) > 1
        ? 'hour'
        : 'minute';
    const data: {[key: string]: AggregationValue[]} = await lastValueFrom(
      this.sensorDataService.getChartRangeData({
        unitGrouping,
        groupingMethod: 'avg',
        groupingKey: 'sensorId',
        kinds: this.kinds,
        start: this.rangeValue.start,
        end: this.rangeValue.end,
        realEstateStructureId: this.realEstateStructureId,
      })
    );
    each(this.sensors, (sensor, index) =>
      this.chart.setSerieData(
        map(get(data, sensor.id, null), d => [d.name, d.value]),
        index
      )
    );
    this.chart.setExtremes(this.rangeValue.start, this.rangeValue.end);
    this.chart.setLoading(false);
  }
}
