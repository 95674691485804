/** @format */

import {propertyMap} from 'model-mapper';

export class GroupChartData {
  @propertyMap()
  public dju: [number, number][];

  @propertyMap()
  public outsideTemperature: [number, number, number, number][];

  @propertyMap()
  public indoorTemperature: [number, number][];

  @propertyMap()
  public ecsDeparture: [number, number, number, number, number][];

  @propertyMap()
  public heatingDeparture: [number, number][];

  @propertyMap()
  public primaryEnergy: [number, number][];

  @propertyMap()
  public consumption: [number, number][];
}
